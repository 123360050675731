<template>
  <div>
    <div class="w-full min-h-screen md:h-screen md:overflow-hidden flex flex-row text-new-black font-visby">

      <!-- Left -->
      <div class="hidden md:block w-1/2 h-screen bg-white bg-center bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('../../assets/images/schule.jpg') + ')' }">
        <div class="w-full h-full bg-black opacity-5"></div>
      </div>

      <!-- Right -->
      <div class="w-full md:w-1/2 p-5 h-auto md:overflow-auto md:p-12 mt-5 md:mt-0 bg-new-yellow font-visby text-new-black select-none" :class="{'pt-safe' : parseInt(sat) !== 0}">
        <backSVG @click="goHome()" />
        <div class="mt-6 font-visby font-bold text-3xl">Datenschutz</div>

        <!-- Datenschutz -->
          <tipTap :content="dataPolicy.data.attributes.Text" />
        <!-- Datenschutz -->

      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import iosVibrations from '../../mixins/iosVibrations.vue';
import detectNotch from '../../mixins/detectNotch.vue';

// Components
import backSVG from '../../assets/svg/back.vue';
import tipTap from '../../symbols/tipTap.vue';

export default {
  name: 'privacy',
  mixins: [iosVibrations, detectNotch],
  components: {
    tipTap,
    backSVG,
  },

  computed: {
    dataPolicy() {
      return this.$store.state.auth.dataPolicy;
    },
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
